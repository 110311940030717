import { useState, useEffect } from "react"
import { Twemoji } from 'react-emoji-render';


import {
    fieldValue,
    myFirebase,
    twitterProvider,
    googleProvider,
    db,
    Timestamp
  } from "../firebase/firebase";

const Home = () => {

    const [drive, setDrive] = useState(false)
    const [gumroad, setGumroad] = useState(false)
    const [gsd, setGsd] = useState(false)
    const [g, setG] = useState([])
    const [v, setV] = useState("")
    const [email, setEmail] = useState("")

    useEffect(() => {
        db.collection("g")
        .orderBy("t", "desc")
        .onSnapshot((snap) => {
            let g = [];
            snap.docs.forEach((gs) => {
            let guess = gs.data();
            guess["id"] = gs.id;
            g.push(guess);
            });
            setG(g);
        });
    }, [])


    // const signInWithGoogleDrive = () => {
    //     console.log("SIGN IN WITH DRIVE")
    //     setDrive(!drive)
    // }

    // const signInWithGumroad = () => {
    //     console.log("SIGN IN WITH GUMROAD")
    //     setGumroad(!gumroad)
    // }

    const submitV = () => {
    if(!gsd && v !== "" && email !== "") {
        setGsd(true)
        let vv = v;
        let ee = email
        setV("")
        setEmail("")
        db.collection('g')
        .add({
            gs: vv,
            t: Timestamp.fromDate(new Date())
        }).then(r => {
            return db.collection('email').doc(r.id).set({
                email: ee
            })
        }).then(r => {

        })
    }
    }


    return (  
        // <>
        //     <h1 style={{margin: '16px 16px 2px 16px', fontWeight: 600}}>Onederwall</h1>
        //     <h4 style={{margin: '0px 16px', fontWeight: 600}}>Google sheet, doc and airtable paywalls</h4>

            
        //     <div style={{margin: '16px 16px 2px 16px', fontWeight: 900}}>
        //         {/* sign in with google drive */}
        //         <button onClick={signInWithGoogleDrive} style={{width: 200, padding: 16, border: 'none', backgroundColor: drive ? "grey" : "black", color: "white"}}>Sign in with Google Drive</button>
        //     </div>
        //     <div onClick={signInWithGumroad} style={{margin: '0px 16px 2px 16px',fontWeight: 900}}>
        //         {/* sign in with gumroad */}
        //         <button style={{width: 200, padding: 16, border: 'none', backgroundColor: gumroad ? "grey" : "black", color: "white"}}>Sign in with Gumroad</button>
        //     </div>

        // </>
        <div component='h1' className="center">
            <div style={{fontFamily: 'Roboto', fontWeight: 300, fontSize: 64, marginBottom: 16}}>
                <span style={{fontWeight: 600}}>One</span>
                der
                <span style={{fontWeight: 600}}>wall </span>
            </div>
            <div style={{width: '80%', fontFamily: 'Roboto', fontWeight: 300, fontSize: 16}}>
                I just bought this domain for a new side-project. 
                Given the name, take your best guess as to what I'm building. 
                The best guess will get lifetime free access.
            </div>
            <div style={{width: '80%', fontFamily: 'Roboto', fontWeight: 300, fontSize: 16, marginTop: 16}}>
                Follow me on
                <a target="_blank" href="https://twitter.com/dijkstradev/status/1414295786297384972" style={{color: 'black', textDecoration: 'none', cursor: 'pointer', fontWeight: 600}}> Twitter</a> for hints & updates.
            </div>
            <input 
                type={'text'}
                value={v}
                onChange={(e) => {
                    setV(e.target.value)
                }}
                maxlength={140}
               style={{ fontWeight: 300, marginTop: 16, border: '1pt solid #efefef', fontFamily: 'Roboto', fontSize: 16, width: '75%', borderRadius: 1, padding: '12px 8px', backgroundColor: '#fafafa'}}
               placeholder=' Take your guess'/>
            <div/>
            <input 
                type={'email'}
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value)
                }}
                maxlength={140}
               style={{ fontWeight: 300, marginTop: 8, border: '1pt solid #efefef', fontFamily: 'Roboto', fontSize: 16, width: '75%', borderRadius: 1, padding: '12px 8px', backgroundColor: '#fafafa'}}
               placeholder=' Your email'/>
            <div/>
            <div style={{width: '80%', fontFamily: 'Roboto', fontWeight: 300, fontSize: 14, color: '#aaaaaa', marginTop: 2}}>
               I will only use this to nofity the winner
            </div>
            <button disabled={gsd} onClick={submitV}>
                Submit
            </button>
            {gsd? 
            <div style={{width: '80%', fontFamily: 'Roboto', fontWeight: 300, fontSize: 16}}>
               Thank you for your guess <Twemoji text="🥳"/>
            </div>
            : ""}
            <div style={{backgroundColor: '#fafafa',borderRadius: 1, border: '1pt solid #efefef',  width: '72%', fontFamily: 'Roboto', fontWeight: 600, fontSize: 16, marginTop: 32, padding: '12px 14px', color: 'black'}}>
            Guesses:
            {g.map((guess, i) => {
                return (
                    <div style={{backgroundColor: '#fafafa',borderRadius: 1, fontWeight: 300, marginTop: 16, paddingBottom: 8}}>
                        {(i + 1) + ". " + guess['gs']}
                    </div>
                )
            })}
            </div>
        </div>
    );
}
 
export default Home;