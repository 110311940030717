import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore'

  const firebaseConfig = {
    apiKey: "AIzaSyCydJvO7oyxHbHpQQmXP_KehwnAD1JeUgU",
    authDomain: "onederwall-web.firebaseapp.com",
    databaseURL: "https://onederwall-web.firebaseio.com",
    projectId: "onederwall-web",
    storageBucket: "onederwall-web.appspot.com",
    messagingSenderId: "759732958319",
    appId: "1:759732958319:web:a5e61b72e768d1af09899a"
  };

export const myFirebase = firebase.initializeApp(firebaseConfig);

export var googleProvider = new firebase.auth.GoogleAuthProvider();

export const db = myFirebase.firestore();

export const Timestamp = firebase.firestore.Timestamp

export const fieldValue = firebase.firestore.FieldValue;

export const auth =  firebase.auth();
